import React, {useState, useEffect, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {Alert, Button, Badge} from 'reactstrap'
import compose from 'lodash/flowRight'
import useSmartNavigate from 'hooks/useSmartNavigate'
import useUTCTimeout from 'hooks/useUTCTimeout'
import useForceRender from 'hooks/useForceRender'
import useDebug from 'hooks/useDebug'
import {useInterval} from "usehooks-ts";

import ListWithSort from 'components/blocks/ListWithSort'
import Block from 'components/fieldBlocks/Block'
import MarkdownVal from 'components/fields/MarkdownVal'
import UTCTimerSpan from 'components/elements/UTCTimerSpan'
import withInitialDataLoadWaiting from 'hocs/withInitialDataLoadWaiting'
import withUriParams from 'hocs/withUriParams'
import SolutionsView, {getScenarioSolutionsStats} from './SolutionsView'
import SaveButtonsBlock from 'components/blocks/SaveButtonsBlock'

import {queryScenario, querySolutions} from '../data/eventQueries'
import {createSolution, deleteSolution, cloneSolution, registerInEvent, deleteScenarioScenarioUserCustomization} from '../data/eventMutations'

import {convertOldJSONPrototypeParameters, convertOldJSONPrototypeResults} from 'appEvent/data/eventObjects'
import {breadcrumbsStore} from 'appBase/TopNav'
import entityUtils from 'utils/entityUtils'
import dateUtils, {MAX_DATE} from 'utils/dateUtils'
import {parseBackendMessage} from 'utils/translationUtils'
import {SCENARIO} from 'constants/constants'
import {useMatch} from '@reach/router'


const ScenarioView = compose(
    withUriParams([
        ['solutionsMode', String]]),
    queryScenario,
    //pollSolutions,
    querySolutions,
    createSolution,
    deleteSolution,
    cloneSolution,
    registerInEvent,
    deleteScenarioScenarioUserCustomization,
    withInitialDataLoadWaiting(['scenario']) //, 'solutions'])
)(props => {
    useDebug("ScenarioView", props);

    const {
        solutionsMode, user, event, solutions, additionalEventStatus, additionalButtonsBlockInfo,
        createSolution, deleteSolution, cloneSolution, registerInEvent, deleteScenarioScenarioUserCustomization,
        loading,
        // features:
        enableBackLink, showBackLink } = props;

    const uriMatch = useMatch('/:lang/:region/*');
    const navigate = useSmartNavigate();
    const forceRender = useForceRender();
    const {t, i18n} = useTranslation();
    const _t = (value, obj) => t('appEvent.views.ScenarioView.' + value, obj);

    const [error, setError] = useState(null);

    // parsing JSON

    const scenario = useMemo(() => (
        props.scenario && {
            ...props.scenario,
            prototypeParameters: entityUtils.safeJSONParse(convertOldJSONPrototypeParameters(props.scenario.prototypeParameters)),
            prototypeResults: entityUtils.safeJSONParse(convertOldJSONPrototypeResults(props.scenario.prototypeResults)),
            parameters: entityUtils.safeJSONParse(props.scenario.parameters)
        }), [props.scenario]);

    // preparing links

    const containerId = SCENARIO;
    breadcrumbsStore.register(containerId);
    const breadcrumbs = [];
    scenario && breadcrumbs.push({
        name: scenario.name,
        to: props.uri,
        back: enableBackLink && 1,
        nonBlocking: true
    });

    useEffect(() => {
        const isTerminal = !solutionsMode;
        breadcrumbsStore.set(containerId, breadcrumbs, isTerminal);
    });

    // force render if time

    const UTCDateTimestamp = dateUtils.getCurrentServerUTCDate().getTime();
    const eventEndsAtUTCTimestamp = Date.parse(event.endsAt) || MAX_DATE;       // MAX_DATE if undefined
    const scenarioEndsAtUTCTimestamp = (scenario && Date.parse(scenario.endsAt)) || MAX_DATE; // MAX_DATE if undefined
    const endsAtUTCTimestamp = dateUtils.getMinOfExistingDates(eventEndsAtUTCTimestamp, scenarioEndsAtUTCTimestamp);
    const isEnded = endsAtUTCTimestamp <= UTCDateTimestamp;
    const disabled = props.disabled || isEnded;
    useUTCTimeout(
        forceRender,
        !isEnded ? endsAtUTCTimestamp : null
    );

    const isScenarioCustomizationRunning =
        scenario?.scenarioUserCustomizationStatus &&
        !['finished', 'failed'].includes(scenario.scenarioUserCustomizationStatus) ;
    const isScenarioCustomizationFailed =
        scenario?.scenarioUserCustomizationStatus &&
        ['failed'].includes(scenario.scenarioUserCustomizationStatus) ;

    useInterval(() => {
        props.refetch.scenario();
    }, isScenarioCustomizationRunning ? 1000 : null);

    // subnavigation

    if (!scenario ||                                        // no scenario was loaded (or not authorized)
        scenario.eventId !== event.id ||                    // not matching the event
        (solutionsMode && solutionsMode !== 'solutions'))     // wrong internal location
    {
        navigate(`${props.uri}/..`);
        return null;
    }


    // rendering

    const sections = [];

    if (scenario.description) {
        sections.push({
            className: "description-tab-icon",
            name: _t('description'),
            title: _t('description'),
            key: "description"
        });
    }
    if (scenario.descriptionParameters) {
        sections.push({
            className: "init-data-tab-icon",
            name: _t('descriptionParameters'),
            title: _t('descriptionParameters'),
            key: "descriptionParameters"
        });
    }
    if (scenario.descriptionScore) {
        sections.push({
            className: "criteria-tab-icon",
            name: _t('descriptionScore'),
            title: _t('descriptionScore'),
            key: "descriptionScore"
        });
    }
    sections.push({
        className: "solutions-tab-icon",
        name: _t('solutions'),
        title: _t('solutions'),
        key: "solutions"
    });

    if (isScenarioCustomizationRunning || isScenarioCustomizationFailed) {
        ['description', 'descriptionParameters', 'descriptionScore'].forEach(descriptionField =>
            scenario[descriptionField] = scenario[descriptionField] && (
                isScenarioCustomizationRunning
                    ? _t('customizationIsRunning')
                    : _t('customizationFailed')));
    }


    const headers = ["#", _t('creationDate'), _t('sendDate'),
                          _t('student'), _t('state'),
                          _t('comment'), _t('grade')];

    const {maxSolutionsNumber, isMaxSentSolutionsNumberReached} =
        getScenarioSolutionsStats(scenario, solutions);

    let rows = [];
    if (loading.solutions) {
        rows = [{fields: [_t('loading')], notSelectable: true}];
    }
    if (solutions) {
        rows = solutions.map((s) => {
            let state = "";
            switch (s.status) {
                case "ready":
                    state = _t('ready');
                    break;
                case "running":
                    // TODO: сделать: 1. менять текст только для generative_test, 2. заменить строки перевода на runningCalculation и runningScoring
                    state = ["test", "generative_test", "prog"].includes(scenario.type) ?
                        _t('running2'):
                        _t('running');
                    break;
                case "evaluating":
                    state = _t('evaluating');
                    break;
                case "finished":
                    state = _t('finished');
                    break;
                default:
                    state = _t('error');
            }

            const row = {
                fields: [<span className={"sim-" + s.status + "-name"}>{s.number}</span>,
                    <span>{dateUtils.dateToLocaleDateTimeString(s.createdAt, i18n)}</span>,
                    <span>{dateUtils.dateToLocaleDateTimeString(s.modifiedAt, i18n)}</span>,
                    <span>{s.user.name}</span>,
                    <span>{state}</span>, <span>{s.description}</span>,
                    <span>{s.score && s.score + _t('points')} </span>],
                actions: {
                    "select": () => navigate(`${props.uri}/solutions/${s.id}`)
                }
            };

            if (!disabled) {
                row.actions.clone = !isMaxSentSolutionsNumberReached && (() => cloneSolution(s.id)
                    .then(({data: {cloneSolution: {solution, error}}}) => {
                        setError(error ? parseBackendMessage(error, t): null);
                        if (!error) {
                            navigate(`${props.uri}/solutions/${solution.id}`);
                        }
                    }));

                if (s.status === "ready" && s.user.id === user.id) {
                    row.actions.removeConfirmation = () => deleteSolution(s.id)
                        .then(({data: {deleteSolution: {error}}}) => setError(error ? parseBackendMessage(error, t): null));
                }
            }
            return row;
        });
    }


    const registeredInEvent = event && (event.isStudent || event.isTester);
    if (!disabled && event) {
        // registered in event
        if (registeredInEvent) {
            // can create solutions
            if (solutions && !isMaxSentSolutionsNumberReached && !(isScenarioCustomizationRunning || isScenarioCustomizationFailed)) {
                rows.push({
                    fields: [_t('createSolution')],
                    className: "text-end",
                    notSelectable: true,
                    actions: {
                        "click": () => createSolution({
                            scenarioId: scenario.id,
                            description: ""
                        }).then(({data: {createSolution: {solution, error}}}) => {
                            setError(error ? parseBackendMessage(error, t): null);
                            if (!error) {
                                navigate(`${props.uri}/solutions/${solution.id}`);
                            }
                        })
                    }
                });
            }
            if (isMaxSentSolutionsNumberReached) {
                rows.push({
                    fields: [t(maxSolutionsNumber === 1
                        ? 'appEvent.views.ScenarioView.uniqueSolution'
                        : 'appEvent.views.ScenarioView.maxSolutionsSent')],
                    className: "text-end",
                    notSelectable: true
                });
            }
        }
        // not registered in event
        if (!registeredInEvent) {
            // self-registration is not avaiable
            if (!event.isRegistrationAllowed) {
                rows.push({
                    fields: [_t('registrationIsNotAllowed')],
                    className: "text-end",
                    notSelectable: true
                });
            }
            // self-registration is avaiable
            if (event.isRegistrationAllowed) {
                // anonymous
                if (!user || !user.id) {
                    rows.push({
                        fields: [_t('notAuthorised')],
                        className: "text-end",
                        notSelectable: true,
                        actions: {
                            "click": () => navigate(
                                `/${uriMatch.lang}/${uriMatch.region}/auth/login`,
                                {state: {goBackLinkData: {to: `/${uriMatch.lang}/${uriMatch.region}/events/${event.id}/${scenario.id}`}}})
                        }
                    });
                }
                // registered user
                if (user && user.id) {
                    rows.push({
                        fields: [_t('notJoined')],
                        className: "text-end",
                        notSelectable: true,
                        actions: {
                            "click": () =>
                                registerInEvent(event.id)
                                    .then(({data: {registerInEvent: {result, error}}}) => {
                                        setError(error ? parseBackendMessage(error, t) : null);
                                    })
                        }
                    });
                }
            }
        }
    }


    return (
        <>
        {error &&
        <Alert className="mb-0" color="danger">{error}</Alert>}
        {!solutionsMode &&
        <div className="flex-grow d-flex flex-column scroll-parent">
            {showBackLink &&
            <SaveButtonsBlock withBack
                              additionalInfo={additionalButtonsBlockInfo}
                              executeWithLock={props.executeWithLock} />}

            {scenario.type !== 'page' &&
            <div className="flex-grow d-flex flex-column scroll" style={{"position": "relative"}}>
                <div className="p-3">
                    {scenario.imageUrl &&
                    <div style={{"position": "absolute", "right": "30px"}}>
                        <img className="big-imgs-choice-pic" src={scenario.imageUrl} alt={scenario.name}/>
                    </div>}
                    <h5>{scenario.name}{additionalEventStatus}</h5>
                    <div>{_t('eventName')} {event.name}</div>
                    <div>{_t('scenarioName')} {scenario.name}</div>
                    {scenario.rating &&
                    <div>{_t('rating')} {scenario.rating} {_t('from')} {scenario.ratingMax}</div>}
                    {!isEnded && endsAtUTCTimestamp < MAX_DATE &&
                    <div>{_t('timeLeft')}<UTCTimerSpan endsAtUTC={endsAtUTCTimestamp} /></div>}
                    {isEnded &&
                    <div className="text-danger">{_t('isEnded')}</div>}
                </div>
                {/* BlockFields propagate data to children */}
                <Block sections={sections}>
                    {['description', 'descriptionParameters', 'descriptionScore']
                        .filter((field) => scenario[field])
                        .map((field) => (
                            <MarkdownVal key={field} section={field} value={scenario[field]} />))}

                    {scenario.description && scenario.type === 'generative_test' &&
                        (event.isAdmin || event.isTester || scenario.scenarioUserCustomizationStatus === 'failed') &&
                        <div section="description">
                           {scenario.scenarioUserCustomizationLog &&
                                <>
                                   <h6>{t('appAdmin.views.LicenseScenarioView.scenarioUserCustomizationLog')}</h6>
                                   <div>{scenario.scenarioUserCustomizationLog}</div>
                                </>}

                            <Button color="danger" className="my-3" disabled={disabled}
                                    onClick={() => {
                                        deleteScenarioScenarioUserCustomization(scenario.id)
                                            .then(({data: {deleteScenarioScenarioUserCustomization: {id, error}}}) =>
                                                setError(error ? parseBackendMessage(error, t) : null))
                                    }}>
                                {_t('resetScenarioUserCustomizationProgram')}
                            </Button>
                        </div>}

                    <Block section="solutions">
                        {event.isTester &&
                            <div><Badge color="secondary">{_t('testMode')}</Badge></div>}
                        <ListWithSort headers={headers}
                                      sizes={[80, 250, 150, 150, "1*", 200]}
                                      rows={rows}  />
                    </Block>
                </Block>
            </div>}

            {scenario.type === 'page' &&
            <div className="flex-grow workpanel-gray bg-white scroll">
                <MarkdownVal value={scenario.description} />
            </div>}
        </div>}

        {solutionsMode === 'solutions' && scenario.type !== 'page' &&
        <SolutionsView event={event} scenario={scenario} additionalEventStatus={additionalEventStatus}
                       scenarioId={scenario.id} // for querySolutions
                       disabled={disabled}   // to turn on read-only mode
                       enableBackLink   // always fullscreen inside scenario
                       showBackLink={showBackLink}
                       isEnded={isEnded}    // to display info message about ending
                       additionalButtonsBlockInfo={additionalButtonsBlockInfo}
                       user={props.user} loading={props.loading} errors={props.errors}  // this will stop SolutionsView.pollSolutions from running
                       solutions={solutions}    // this will stop SolutionsView.querySolutions from running
                       setActionLockWithMessageAndActions={props.setActionLockWithMessageAndActions}
                       resetActionLock={props.resetActionLock}
                       executeWithLock={props.executeWithLock}
                       executeWithConfirmation={props.executeWithConfirmation}
                       uri={`${props.uri}/solutions`} uriParams={props.uriParams} location={props.location} />}
        </>);
});

export default ScenarioView;