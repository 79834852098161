import React, {useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {Router} from '@reach/router'
import useDebug from 'hooks/useDebug'
import useSmartNavigate from 'hooks/useSmartNavigate'

import TopNav, {breadcrumbsStore, linksStore} from 'appBase/TopNav'

import Link from 'components/links/Link'

import UsersView from './views/UsersView'
import AdminView from './views/AdminView'
import RegionsView from './views/RegionsView'
import EmbedView from './views/EmbedView'
import TemplateView from './views/TemplateView'
import DeviceView from './views/DeviceView'
import RegistrationView from './views/RegistrationView'


import {DB_ROLE_NAME_SUPERADMIN} from '../constants/constants'

const ServiceApp = (props) => {
    useDebug("ServiceApp", props);
    const {user, regions} = props;
    const {t} = useTranslation();
    const navigate = useSmartNavigate();

    // initial breadcrumbs
    const containerId = null;
    breadcrumbsStore.register(containerId);
    linksStore.register(containerId);
    const breadcrumbs = [];
    breadcrumbs.push({name: t('appService.control'), to: props.uri});

    useEffect(() => {
        const isTerminalBreadcrumbs = !props.uriParams;
        breadcrumbsStore.set(containerId, breadcrumbs, isTerminalBreadcrumbs);
        linksStore.set(containerId, [], isTerminalBreadcrumbs);
    });

    //access: superadmins only
    const hasAccess = user && user.id && user.roles && user.roles.some(r => r.name === DB_ROLE_NAME_SUPERADMIN);
    if (!hasAccess) {
        navigate(`${props.uri}/..`);
        return null;
    }

    return (
        <div className="d-flex flex-column h-100 w-100">
            <TopNav user={user} regions={regions} skipRootPath={props.uri} executeWithLock={props.executeWithLock} />

            {!props.uriParams &&
            <div className="container mt-3">
                <h5 className="text-center">{t('appService.control')}</h5>

                <div>
                    <div>
                        <Link to={`service/users`}>{t('appService.users')}</Link>
                    </div>
                    <div>
                        <Link to={`service/admin`}>{t('appService.admin')}</Link>
                    </div>
                    <div>
                        <Link to={`service/regions`}>{t('appService.regions')}</Link>
                    </div>
                    <div>
                        <Link to={`service/embed`}>{t('appService.embed')}</Link>
                    </div>
                    <div>
                        <Link to={`service/template`}>{t('appService.templates')}</Link>
                    </div>
                    <div>
                        <Link to={`service/device`}>{t('appService.devices')}</Link>
                    </div>
                    <div>
                        <Link to={`service/registration`}>{t('appService.registration')}</Link>
                    </div>
                </div>
            </div>}

            <Router className="flex-grow d-flex flex-column scroll-parent" basepath={props.uri + '/'}>
                <UsersView path="users/*uriParams" user={user} errors={props.errors} loading={props.loading}
                           setActionLockWithMessageAndActions={props.setActionLockWithMessageAndActions}
                           resetActionLock={props.resetActionLock}
                           executeWithLock={props.executeWithLock}
                           executeWithConfirmation={props.executeWithConfirmation} />
                <AdminView path="admin/*uriParams" user={user} errors={props.errors} loading={props.loading}
                           setActionLockWithMessageAndActions={props.setActionLockWithMessageAndActions}
                           resetActionLock={props.resetActionLock}
                           executeWithLock={props.executeWithLock}
                           executeWithConfirmation={props.executeWithConfirmation} />
                <RegionsView path="regions/*uriParams" user={user} regions={regions} errors={props.errors} loading={props.loading}
                           setActionLockWithMessageAndActions={props.setActionLockWithMessageAndActions}
                           resetActionLock={props.resetActionLock}
                           executeWithLock={props.executeWithLock}
                           executeWithConfirmation={props.executeWithConfirmation} />
                <EmbedView path="embed" user={user} errors={props.errors} loading={props.loading}
                           setActionLockWithMessageAndActions={props.setActionLockWithMessageAndActions}
                           resetActionLock={props.resetActionLock}
                           executeWithLock={props.executeWithLock}
                           executeWithConfirmation={props.executeWithConfirmation} />
                <TemplateView path="template/*uriParams" user={user} errors={props.errors} loading={props.loading}
                           setActionLockWithMessageAndActions={props.setActionLockWithMessageAndActions}
                           resetActionLock={props.resetActionLock}
                           executeWithLock={props.executeWithLock}
                           executeWithConfirmation={props.executeWithConfirmation} />
                <DeviceView path="device/*uriParams" user={user} errors={props.errors} loading={props.loading}
                           setActionLockWithMessageAndActions={props.setActionLockWithMessageAndActions}
                           resetActionLock={props.resetActionLock}
                           executeWithLock={props.executeWithLock}
                           executeWithConfirmation={props.executeWithConfirmation} />
                <RegistrationView path="registration/*uriParams" user={user} errors={props.errors} loading={props.loading}
                           setActionLockWithMessageAndActions={props.setActionLockWithMessageAndActions}
                           resetActionLock={props.resetActionLock}
                           executeWithLock={props.executeWithLock}
                           executeWithConfirmation={props.executeWithConfirmation} />
            </Router>
        </div>
    );
};

export default ServiceApp;