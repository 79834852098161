import React, {useState, useMemo, useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import compose from 'lodash/flowRight'
import {Alert, Button, Input} from 'reactstrap'

import useSmartNavigate from 'hooks/useSmartNavigate'

import {breadcrumbsStore} from 'appBase/TopNav'

import withUriParams from 'hocs/withUriParams'
import withGeneratorDataSource from 'hocs/withGeneratorDataSource'
import PanelModal from 'components/modals/PanelModal'
import SaveButtonsBlock from 'components/blocks/SaveButtonsBlock'
import GeneratorBlock from 'components/fieldBlocks/GeneratorBlock'
import NamedBlock from 'components/blocks/NamedBlock'
import ListWithSort from 'components/blocks/ListWithSort'

import {queryUsers} from '../data/serviceQueries'
import {createUser, updateUser, deleteUser} from '../data/serviceMutations'

import entityUtils from 'utils/entityUtils'
import {parseBackendMessage} from 'utils/translationUtils'
import {SERVICE_USERS_VIEW, EMAIL_REGEX_STRING} from 'constants/constants'


const UsersView = compose(
     withUriParams([['search', String, {optional: true}], ['userId', Number]]),
     queryUsers,
     createUser,
     updateUser,
     deleteUser,
     withGeneratorDataSource
)((props) => {
    const {createUser, updateUser, deleteUser,
           dataSource, setDataSource, saveAndReset, isChanged, hasErrors, onChange, onValidate} = props;

    const createUserProto = [
        {
            name: "#appService.views.UsersView.PanelModal.name#",
            type: "string",
            field: "name",
            required: true
        },
        {
            name: "#appService.views.UsersView.PanelModal.email#",
            type: "string",
            field: "email",
            pattern: {
                pattern: EMAIL_REGEX_STRING,
                error: "#appService.views.UsersView.PanelModal.invalidEmail#",
            },
            required: true
        },
        {
            name: "#appService.views.UsersView.PanelModal.talentId#",
            type: "int",
            field: "talentId"
        },
        {
            name: "#appService.views.UsersView.PanelModal.password#",
            type: "string",
            field: "password",
            required: true
        },
        {
            name: "#appService.views.UsersView.PanelModal.active#",
            type: "enum",
            field: "active",
            stuff: [
              {
                  "name": "#appService.views.UsersView.PanelModal.yes#",
                  "value": true
              },
              {
                  "name": "#appService.views.UsersView.PanelModal.no#",
                  "value": false
              }
            ],
            required: true
        }
    ];

    const updateUserProto = [
        {
            name: "#appService.views.UsersView.PanelModal.id#",
            type: "int",
            field: "id",
            disabled: true,
            required: true
        },
        {
            name: "#appService.views.UsersView.PanelModal.name#",
            type: "string",
            field: "name",
            required: true
        },
        {
            name: "#appService.views.UsersView.PanelModal.email#",
            type: "string",
            field: "email",
            pattern: {
                pattern: EMAIL_REGEX_STRING,
                error: "#appService.views.UsersView.PanelModal.invalidEmail#",
            },
            required: true
        },
        {
            name: "#appService.views.UsersView.PanelModal.talentId#",
            type: "int",
            field: "talentId"
        },
        {
            name: "#appService.views.UsersView.PanelModal.password#",
            type: "string",
            field: "password"
        },
        {
            name: "#appService.views.UsersView.PanelModal.active#",
            type: "enum",
            field: "active",
            stuff: [
              {
                  "name": "#appService.views.UsersView.PanelModal.yes#",
                  "value": true
              },
              {
                  "name": "#appService.views.UsersView.PanelModal.no#",
                  "value": false
              }
            ],
            required: true
        }
    ];
    const [search, setSearch] = useState(props.search || "");
    const [userId, setUserId] = useState(props.userId);
    const [error, setError] = useState(null);

    const navigate = useSmartNavigate();
    const {t} = useTranslation();

    // preparing links
    breadcrumbsStore.register(SERVICE_USERS_VIEW);
    useEffect(() => {
        breadcrumbsStore.set(SERVICE_USERS_VIEW, {name: t('appService.users'), to: `${props.uri}`}, true);
    });

    useEffect(() =>
        setUserId(props.userId),
        [props.userId]);

    const users = props.users && props.users.map(u => ({
        name: u.name,
        fields: [
            u.id,
            u.name,
            u.email,
            u.talentId,
            u.active ? t('appService.views.UsersView.yes'): t('appService.views.UsersView.no')],
        actions: {
            "click": () => navigate(`${props.uri}/${search}/${u.id}${props.location.search}`),
            "removeConfirmation":  () =>
                deleteUser(u.id)
                    .then(({data: {deleteUser: {id, error}}}) => {
                        setError(error ? parseBackendMessage(error, t): null);
                    })
        }
    }));


    // user stable object for dataSource
    const userSource = useMemo(() => {
        if ([null, undefined].includes(userId)) {
            return null;
        }
        if (userId === 0) {
            return {};
        }
        return props.users && props.users.find(u => u.id === userId);
    }, [userId, props.users]);


    // invalid userId, user not found
    if (userId && !userSource) {
        navigate(`${props.uri}/${search}${props.location.search}`);
    }

    // setting dataSource
    useEffect(() => {
        if (![undefined, null].includes(userId)) {
            setDataSource(
                userSource,
                (data) => {
                    data = entityUtils.filterFields(data, [
                        'id',
                        'name',
                        'email',
                        'talentId',
                        'password',
                        'active'
                    ]);

                    const mutationName = userId > 0 ? "updateUser": "createUser";
                    const mutationFunc = userId > 0 ? updateUser: createUser;
                    mutationFunc(data)
                        .then(({data: {[mutationName]: {id, error}}}) => {
                            setError(error ? parseBackendMessage(error, t): null);
                            if (!error) {
                                setUserId(null);
                                navigate(`${props.uri}/${search}${props.location.search}`);
                            }
                        });
                });
        }
    }, [createUser, updateUser, userId, userSource, setDataSource, t, navigate]);

    const onCancel = () => {
        setDataSource(null);
        setUserId(null);
        navigate(`${props.uri}/${search}${props.location.search}`);
    };


    return (
        <div className="d-flex flex-column h-100 w-100">
            <div className="flex-grow d-flex flex-column scroll-parent">

                {error &&
                <Alert className="mb-0" color="danger">{error}</Alert>}

                <div className="flex-grow d-flex scroll-parent">
                    <div className="flex-grow scroll">
                        <h5 className="text-center mt-3">{t('appService.users')}</h5>
                        <div className="container">
                           <Button color="primary" className="m-3"
                                   onClick={() => setUserId(0)}>
                                   {t('appService.views.UsersView.create')}
                            </Button>

                            <NamedBlock name={t('appService.views.UsersView.userSearch')}>
                                <Input type="text" value={search} className="my-3"
                                       placeholder={t('appService.views.UsersView.placeholder')}
                                       onChange={(e) => setSearch(e.nativeEvent.target.value)} />

                                <Button color="primary"
                                        onClick={() => navigate(`${props.uri}/${search}${props.location.search}`)}>
                                    {t('appService.views.UsersView.find')}
                                </Button>
                            </NamedBlock>

                            {users &&
                            <NamedBlock name={t('appService.views.UsersView.results') + " (" + users.length + ")"}>
                                <ListWithSort headers={[
                                    t('appService.views.UsersView.id'),
                                    t('appService.views.UsersView.name'),
                                    t('appService.views.UsersView.email'),
                                    t('appService.views.UsersView.talentId'),
                                    t('appService.views.UsersView.active')]}
                                    sizes={["50*", "100*", "200*", "50*", "50*"]} rows={users} />
                            </NamedBlock>}

                        </div>
                    </div>
                </div>

                {![undefined, null].includes(userId) &&
                <PanelModal show title={userId === 0 ? t('appService.views.UsersView.PanelModal.create'): t('appService.views.UsersView.PanelModal.update')} onClose={onCancel} noPadding scrollable>
                    <SaveButtonsBlock isChanged={isChanged} hasErrors={hasErrors} onSave={saveAndReset} onCancel={onCancel} />
                    <div className="scroll flex-grow d-flex flex-column workpanel-gray">
                        <GeneratorBlock data={dataSource} onChange={onChange} onValidate={onValidate} wide
                                        items={userId === 0 ? createUserProto: updateUserProto} />
                    </div>
                </PanelModal>}
            </div>
        </div>);
});

export default UsersView;