import React, {Component} from 'react'
import {withTranslation} from 'react-i18next'
import {Table, Button} from 'reactstrap'
import {CSVLink} from 'react-csv'
import ReactToPrint from 'react-to-print'
import classnames from 'classnames'

import ActionConfirmationModalContainer, {TrWithActions} from 'components/blocks/ContainersWithActions'
import {toArrayWithLineBreaks} from 'utils/visualHelpers'


class TableBlock extends Component {
    constructor(props) {
        super(props);
        this.state = {
            componentRef: null,
        };
    }

    render() {
        let {headers, rows, selectedIndex, saveable, printable} = this.props;

        const csv = rows.map(str => str.fields);
        csv.unshift(headers);

        return (
            <div>
                {saveable &&
                <CSVLink data={csv} separator=";" uFEFF>
                    <Button color={"link"}><i className="fa fa-save"/></Button>
                </CSVLink>}

                {printable &&
                <ReactToPrint trigger={() => <Button color={"link"}><i className="fa fa-print"/></Button>}
                              content={() => this.componentRef}/>}

                <ActionConfirmationModalContainer>
                    <MyTable {...this.props}
                             ref={el => (this.componentRef = el)}
                             headers={headers}
                             rows={rows}
                             selectedIndex={selectedIndex}/>
                </ActionConfirmationModalContainer>
            </div>);
    }
}


class MyTable extends Component {
    render() {
        const {headers, rows, selectedIndex, nameIndex, onSort, sortIndex, sortDirection, onAction, t} = this.props;

        return (
            // TODO: регулировать отображени striped на уровене стилей (@media print)
            <Table striped size="sm">
                {headers &&
                <thead>
                <tr>
                    {headers.map((h, i) =>
                        <th className="listpanel-header-elem" key={i} onClick={(e) => {
                            e.stopPropagation();
                            if (onSort) {
                                onSort(i);
                            }
                        }}>
                            {h}
                            { (i === sortIndex) && sortDirection &&
                            <i className="fa fa-chevron-up"/> }
                            { (i === sortIndex) && !sortDirection &&
                            <i className="fa fa-chevron-down"/> }
                        </th>)}
                </tr>
                </thead>}
                <tbody>
                {rows && rows.map((r, index) =>
                    <Row key={index} row={r} t={t}
                         rowIndex={index}
                         selectedIndex={selectedIndex}
                         nameIndex={nameIndex}
                         onAction={onAction}/>)}
                </tbody>
            </Table>);
    }
}

const Row = (props) => {
    const {onAction, t} = props;
    const {row, rowIndex, nameIndex, selectedIndex} = props;
    const {actions, isError} = row;
    let {fields} = row;

    const isSelected = rowIndex === selectedIndex;
    const isClickable = actions && ['click', 'select', 'showProperties'].some(a => actions.hasOwnProperty(a));

    let name = t('components.object');
    if (row.hasOwnProperty('name')) {
        name = row.name;
    } else if (fields) {
        const correctedNameIndex = nameIndex >= 0 && nameIndex < fields.length ? nameIndex : 0;
        name = fields[correctedNameIndex];
    }
    fields = fields || [name];
    fields = fields.map(f => toArrayWithLineBreaks(f));

    return (
        <TrWithActions className={classnames({clickable: isClickable, "table-active": isSelected})}
                       data={{...row, name}} onAction={onAction}>
            {fields.map((f, i) =>
                <td key={i} className={classnames({"text-danger": isError})}>{f}</td>)}
        </TrWithActions>);
};

export default withTranslation()(TableBlock);