import React, {Fragment} from 'react'
import {useApolloClient} from '@apollo/client'
import {useTranslation} from 'react-i18next'
import {decorate, observable} from 'mobx'
import {observer} from 'mobx-react'
import {useLocation, useMatch} from '@reach/router'
import {useCookies} from 'react-cookie'
import useSmartNavigate from 'hooks/useSmartNavigate'

import {Nav, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown, NavLink} from 'reactstrap'
import BlockingLink from 'components/links/BlockingLink'
import Link from 'components/links/Link'

import {setSearchParam} from 'utils/urlUtils'
import {DB_ROLE_NAME_SUPERADMIN, DB_ROLE_NAME_WRITER, PROFILE_VIEW} from 'constants/constants'


function LinkStore() {
    this.containers = [];
    this.links = {};
    this.enabled = true;
    this.register = (containerId) => {
        // any 'false' is like null
        if (!containerId) {
            containerId = null;
        }
        // obtaining new container instance (in a containers array)
        let containerIndex = this.containers.indexOf(containerId);
        if (containerIndex === -1) {
            this.containers.push(containerId);
        }
    };
    this.set = (containerId, links, isTerminal) => {
        // console.log(`setting-container: ${containerId}`);
        // any 'false' is like null
        if (!containerId) {
            containerId = null;
        }
        // links is always an array
        if (!Array.isArray(links)) {
            links = [links];
        }
        // is terminal?
        if (isTerminal) {
            let containerIndex = this.containers.indexOf(containerId);
            const deletedIds = this.containers.splice(containerIndex + 1);
            deletedIds.forEach(id => delete this.links[id]);
        }
        // setting new links
        // informing mobX that our object has changed:
        this.links = {...this.links, [containerId]: links};
    };
    this.disable = () => {
        this.enabled = false;
    };
    this.enable = () => {
        this.enabled = true;
    };
    this.getAll = () => {
        if (!this.enabled) {
            return [];
        }
        const links = [];
        this.containers
            .filter(id => this.links[id])
            .forEach(id => {
                links.push(...this.links[id]);
            });
        return links;
    };
}


export const breadcrumbsStore = new LinkStore();

decorate(breadcrumbsStore, {
    links: observable
});

export const linksStore = new LinkStore();

decorate(linksStore, {
    links: observable
});

//is used on authorization page
export const changeLanguageTo = (lang, setCookie, apolloClient, i18n) => {
    console.log("set language -> " + lang);
    i18n.changeLanguage(lang).then(() => {
        // inform backend about language
        setCookie('language', i18n.resolvedLanguage, {path: '/', maxAge: 60 * 60 * 24 * 365});
        console.log("language cookie set: " + i18n.resolvedLanguage);

        // reset apollo client store
        apolloClient.resetStore();
        console.log("apollo reset store!");
    });
};

const changeRegionTo = (regionCode, setCookie, apolloClient, i18n) => {
    console.log("set region -> " + regionCode);
    // inform backend about region
    setCookie('region', regionCode, {path: '/', maxAge: 60 * 60 * 24 * 365});
    console.log("region cookie set: " + regionCode);

    // reset apollo client store
    apolloClient.resetStore();
    console.log("apollo reset store!");
}



const TopNav = observer((props) => {
    const apolloClient = useApolloClient();
    const uriMatch = useMatch('/:lang/:region/*');
    const location = useLocation();
    const navigate = useSmartNavigate();
    const {t, i18n} = useTranslation();
    const [,setCookie] = useCookies([]);

    const {skipRootPath, executeWithLock, user, regions} = props;

    const breadcrumbsData = breadcrumbsStore.getAll();
    const linksData = linksStore.getAll();

    const dropDownLanguageLinksData = {
        // TODO: сделать в константах словарь перечислений для отображения языка
        name: (<>
                <i className="fa fa-language" />&nbsp;
                {i18n.resolvedLanguage === "ru" && "RU"}
                {i18n.resolvedLanguage === "en" && "EN"}
            </>),
        links: [
            {
                name: <span>Русский {i18n.resolvedLanguage === "ru" && <i className="fa fa-check" />}</span>,
                action: () => {
                    changeLanguageTo("ru", setCookie, apolloClient, i18n);
                }
            },
            {
                name: <span>English {i18n.resolvedLanguage === "en" && <i className="fa fa-check" />}</span>,
                action: () => {
                    changeLanguageTo("en", setCookie, apolloClient, i18n);
                }
            }
        ]
    };

    const dropDownRegionLinksData = {
        name: (<>
            <i className="fa fa-globe" />&nbsp;
            {uriMatch.region}
        </>),
        links: regions.map(r => ({
                   name: <span>{r.name} {r.code === uriMatch.region && <i className="fa fa-check" />}</span>,
                   action: () => {
                       changeRegionTo(r.code, setCookie, apolloClient, i18n);
                   }
               }))
    };

    let rootLinksData = [];

    if (user && !user.id) {
        rootLinksData.push(dropDownLanguageLinksData);
        rootLinksData.push(dropDownRegionLinksData);
        rootLinksData.push({name: t('appBase.TopNav.registrationAndLogin'), to: `/${uriMatch.lang}/${uriMatch.region}/auth/login`});
        rootLinksData.push({name: t('appBase.TopNav.allEvents'), to: `/${uriMatch.lang}/${uriMatch.region}/events`});
        rootLinksData.push({name: t('appBase.TopNav.materials'), to: `/${uriMatch.lang}/${uriMatch.region}/materials`});
    }

    if (user && user.id) {
        rootLinksData.push(dropDownLanguageLinksData);
        rootLinksData.push(dropDownRegionLinksData);
        const dropdownAdminLinksData = [];
        if (user.licenses && user.licenses.length > 0) {
            dropdownAdminLinksData.push({name: t('appBase.TopNav.admin'), to: `/${uriMatch.lang}/${uriMatch.region}/admin`});
        }
        if (user.roles && user.roles.some(r => r.name === DB_ROLE_NAME_SUPERADMIN)) {
            dropdownAdminLinksData.push({name: t('appBase.TopNav.license'), to: `/${uriMatch.lang}/${uriMatch.region}/license`});
        }
        if (user.roles && user.roles.some(r => r.name === DB_ROLE_NAME_SUPERADMIN)) {
            dropdownAdminLinksData.push({name: t('appBase.TopNav.service'), to: `/${uriMatch.lang}/${uriMatch.region}/service`});
        }
        if (user.roles && user.roles.some(r => r.name === DB_ROLE_NAME_WRITER || r.name === DB_ROLE_NAME_SUPERADMIN)) {
            dropdownAdminLinksData.push({name: t('appBase.TopNav.catalog'), to: `/${uriMatch.lang}/${uriMatch.region}/catalog`});
        }
        rootLinksData.push({
            name: t('appBase.TopNav.admin'),
            links: dropdownAdminLinksData
        });

        rootLinksData.push({name: t('appBase.TopNav.allEvents'), to: `/${uriMatch.lang}/${uriMatch.region}/events`});
        rootLinksData.push({name: t('appBase.TopNav.materials'), to: `/${uriMatch.lang}/${uriMatch.region}/materials`});

        linksData.forEach(ld => rootLinksData.push(ld));

        rootLinksData.push({
            name: t('appBase.TopNav.profile'),
            to: `${location.pathname}${setSearchParam(location.search, PROFILE_VIEW)}`,
            state: location.state,
            nonBlocking: true
        });

        rootLinksData.push({name: t('appBase.TopNav.exit'), to: `/${uriMatch.lang}/${uriMatch.region}/auth/logout`});
    }

    rootLinksData = rootLinksData.filter(ld => ld.to !== skipRootPath);

    // rendering:
    // console.log(`rendering: ${JSON.stringify(breadcrumbsData)}`);

    const renderLinkDataWithSep = (key, ld, sep) => (
        <Fragment key={key}>
            {ld.links && ld.links.length === 1 &&
            renderLinkDataWithSep(null, ld.links[0], null)}

            {ld.links && ld.links.length > 1 &&
            <UncontrolledDropdown tag="span">
                <DropdownToggle nav caret>{ld.name}</DropdownToggle>
                <DropdownMenu>
                    {ld.links.map((ild, i) =>
                        <DropdownItem key={i} onClick={(e) => {
                            e.preventDefault();
                            if (ld.nonBlocking) {
                                (ild.action ? ild.action : () => navigate(ild.to, {state: ild.state}))();
                            } else {
                                executeWithLock(ild.action ? ild.action : () => navigate(ild.to, {state: ild.state}));
                            }
                            return false;
                        }}><NavLink>{ild.name}</NavLink></DropdownItem>)}
                </DropdownMenu>
            </UncontrolledDropdown>}

            {!ld.links && (ld.nonBlocking
                ? <Link to={ld.to} state={ld.state}>{ld.name}</Link>
                : <BlockingLink to={ld.to} state={ld.state} action={ld.action}
                                executeWithLock={executeWithLock}>{ld.name}</BlockingLink>)}
            {sep &&
            <span> {sep} </span>}
        </Fragment>);

    return (
        <div className="border border-dark border-start-0 border-top-0 border-end-0 toppanel hstack">
            <Nav>
                {breadcrumbsData.map((ld, i) => {
                    if (i < breadcrumbsData.length - 1) {
                        return renderLinkDataWithSep(i, ld, '>');
                    } else {
                        return <span key={i}>{ld.name}</span>;
                    }
                })}
            </Nav>
            <Nav className="ms-auto">
                {rootLinksData.map((ld, i) => {
                        return renderLinkDataWithSep(i, ld, i < rootLinksData.length - 1 ? '|' : null)
                    })}
             </Nav>
        </div>);
});


export default TopNav;