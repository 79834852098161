import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import compose from 'lodash/flowRight'
import {useLocation, useMatch} from '@reach/router'
import useSmartNavigate from 'hooks/useSmartNavigate'

import MarkdownRender from 'components/elements/MarkdownOutput'
import DialogModal from 'components/modals/DialogModal'
import {registerInEvent} from '../data/eventMutations'

import {setSearchParam} from 'utils/urlUtils'
import {parseBackendMessage} from 'utils/translationUtils'


const RegistrationModal = compose(
    registerInEvent
)((props) => {

    const {eventId, event, user, registerInEvent} = props;

    const [mode, setMode] = useState(event.isInfo ? 'info' : 'registered');
    const [error, setError] = useState('');

    const uriMatch = useMatch('/:lang/:region/*');
    const location = useLocation();
    const navigate = useSmartNavigate();
    const {t} = useTranslation();

    if (!user || !user.id) {
        return null;
    }

    //  rendering

    const message =
        mode === 'info' && location.state && location.state.error ? `${t('appEvent.modals.RegistrationModal.error')} ${location.state.error}` :
        mode === 'info' ? (
            event.isRegistrationAllowed
                ? (event.isOnti && !user.talentId
                    ? t('appEvent.modals.RegistrationModal.addTalent')
                    : t('appEvent.modals.RegistrationModal.joinEvent'))
                : t('appEvent.modals.RegistrationModal.connectOrganizer')
        ) :
        mode === 'registering' ? t('appEvent.modals.RegistrationModal.registering') :
        mode === 'registered' ? t('appEvent.modals.RegistrationModal.registered') :
        mode === 'error' ?
            `${t('appEvent.modals.RegistrationModal.registrationError')} ${error}`:
            t('appEvent.modals.RegistrationModal.close');

    let modalActions = [
        (event.isRegistrationAllowed && (mode === 'info' || mode === 'registering')) && {
            key: "register",
            name: event.isOnti && !user.talentId ? t('appEvent.modals.RegistrationModal.addTalent2') : t('appEvent.modals.RegistrationModal.join'),
            color: "primary",
            disabled: mode !== 'info',
            notHideOnAction: true,
            action: () => {
                if (event.isOnti && !user.talentId) {
                    navigate(`${location.pathname}${setSearchParam(setSearchParam(location.search, 'profile'), 'addTalent')}`, {state: location.state});
                } else {
                    // eventId is either normal event.id or event.link_uuid
                    registerInEvent(eventId).then(({data: {registerInEvent: {result, error}}}) => {
                        setMode(result ? 'registered' : 'error');
                        setError(error ? parseBackendMessage(error, t): null);
                    });
                    setMode('registering');
                }
            }
        },
        {
            key: "close", name: t('appEvent.modals.RegistrationModal.close'),
            action: () => navigate(mode === 'registered' ? props.uri : `/${uriMatch.lang}/${uriMatch.region}/events`)
        }
    ];
    modalActions = modalActions.filter(a => a);

    return (
        <DialogModal key="M" show
                      title={`${t('appEvent.modals.RegistrationModal.newEvent')} - ${event.name}`}
                      actions={modalActions}>
            <MarkdownRender value={event.description} />
            {message}
        </DialogModal>
    )
});

export default RegistrationModal;